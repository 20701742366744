<template>
    <div id="app_user_company_list">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    增加
                </el-button>
            </div>

            <div class="head_top_title">公司管理</div>

        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="padding:10px 0;">
                <el-input  v-model="searchID" placeholder="请输入公司名称或id" prefix-icon="el-icon-search" size="small"
                style="width:200px;margin-left:20px;"></el-input>
                <el-button type="primary"  plain size="mini" style="margin-left:20px;" @click="submit_cha_xun()">查询</el-button>
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-pagination_height)+'px')}">
                <template v-for="item in tableData">
                    <!-- 卡片 shadow="hover"鼠标悬浮时显示卡片阴影 -->
                    <el-card class="box-card" shadow="hover" style="margin-top:10px;">
                        <div slot="header" class="clearfix">
                            <span>ID:{{ item.id }}</span>

                            <el-link @click="show_user(item.id,item.company_name)" type="primary" :underline="false" style="float: right;margin-right:10px;">
                                客户
                            </el-link>

                            <el-link @click="upd(item)" type="primary" :underline="false" style="float: right;margin-right:10px;">
                                修改
                            </el-link>

                        </div>
                        <div class="card_div_class">
                            {{ item.company_name }}
                        </div>
                        <div class="card_div_class">
                            {{ item.signCompanyName }}
                        </div>
                        <div class="card_div_class">
                            余额：{{ item.blanceNum }}
                        </div>
                        <div class="card_div_class">
                            公司IP：{{ item.ip }}
                        </div>
                        <div class="card_div_class">
                            通知类型：{{ item.notice_type == -1 ? '不通知' : '微信' }}
                        </div>
                        <div class="card_div_class" v-if="item.notice_type != -1">
                            通知群名：{{ item.notice_name }}
                        </div>
                        <div class="card_div_class">
                            接入端口号：{{ item.join_port }}
                        </div>

                        <div class="card_div_class">
                            合同号：{{ item.contractnum }}
                        </div>
                        <div class="card_div_class">
                            商务类型：{{ item.business_type == 0 ? '渠道' : '直客' }}
                        </div>

                        <div class="card_div_class">
                            备注：{{ item.note }}
                        </div>

                    </el-card>
                </template>
                
            
            </el-row>

            <el-row id="pagination_id" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10, 20, 30, 40]"
                     :page-size="10"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>
            

        </el-row>

        
        
    <!-- 展示客户组件 -->
    <ShowUser :prop_change_i="prop_user_show_i" :company_id="prop_user_show_company_id" :company_name="prop_user_show_company_name"></ShowUser>

    <!-- 客户公司增加或修改组件 -->
    <UserCompanyAddUpd :prop_change_i="prop_user_company_add_upd_i"
        :prop_item="prop_user_company_add_upd_item"
        :prop_type="prop_user_company_add_upd_type"
        @result="userCompanyAddUpdResult"
      ></UserCompanyAddUpd>


    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import ShowUser from './user_company_list_show_user.vue';//组件 菜单详情
import UserCompanyAddUpd from './user_company_add_upd.vue';//组件 客户公司增加或修改



//import axios from "axios";
export default {

    components: {
        ShowUser,
        UserCompanyAddUpd,
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            searchID:"",//搜索的id

            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            
            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度


            //客户展示
            prop_user_show_i:0,//客户展示 组件 弹出框是否可见
            prop_user_show_company_id:0,//客户展示  公司详情id
            prop_user_show_company_name:"",//客户展示  公司名

            //客户公司信息修改
            prop_user_company_add_upd_i:0,//客户公司信息增加或修改  组件 弹出框是否可见
            prop_user_company_add_upd_type:'add',//客户公司信息增加或修改  组件 类型  add：增加  upd:修改
            prop_user_company_add_upd_item:{},//客户公司信息增加或修改  组件 参数对象


        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        console.log("activated user_company_list");
        // this.searchID = this.$options.data().searchID;//单个恢复初始化
        // this.indexPage = this.$options.data().indexPage;//单个恢复初始化
        // this.pageSize = this.$options.data().pageSize;//单个恢复初始化
        // this.tableData = this.$options.data().tableData;//单个恢复初始化
        // this.tableData_total = this.$options.data().tableData_total;//单个恢复初始化

        //重置所有数据
        Object.assign(this.$data, this.$options.data.call(this));
        //初始化设置
        this.initSetUp();
         
         //查询
        this.submit_cha_xun();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 130);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

        },

        //查询
        submit_cha_xun(){
            //请求接口
            API.CompanyServlet({
                param: "list",
                searchID:this.searchID,
               
                indexPage:this.indexPage,
                pageSize:this.pageSize,

            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;

                    this.$nextTick(function(){
                        //获取分页的高度
                        this.pagination_height = document.getElementById('pagination_id').clientHeight;
                    });

                    
                } else {
                }
                
            });
        },
        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },

        //展示客户
        show_user(company_id,company_name){
            console.log("展示客户-company_id:"+company_id+" company_name:"+company_name);
            this.prop_user_show_i ++;//客户展示 组件 弹出框是否可见
            this.prop_user_show_company_id = company_id;//客户展示  公司id
            this.prop_user_show_company_name = company_name;//客户展示  公司id
        },

        //修改
        upd(item){
            console.log("修改");
            this.prop_user_company_add_upd_i ++;//客户公司信息增加或修改  组件 弹出框是否可见
            this.prop_user_company_add_upd_type = 'upd';//客户公司信息增加或修改  组件 类型  add：增加  upd:修改
            this.prop_user_company_add_upd_item = item;//客户公司信息增加或修改  组件 参数对象
        },
        //增加
        add(item){
            console.log("增加");
            this.prop_user_company_add_upd_i ++;//客户公司信息增加或修改  组件 弹出框是否可见
            this.prop_user_company_add_upd_type = 'add';//客户公司信息增加或修改  组件 类型  add：增加  upd:修改
            this.prop_user_company_add_upd_item = {};//客户公司信息增加或修改  组件 参数对象
        },
        //客户增加或修改组件 结果回调方法
        userCompanyAddUpdResult(){
            //查询-- 请求接口
            this.submit_cha_xun();
        },

        //返回
        goBack() {
            API.router_to("/user_index");
        }
    }
};

</script>

<style scoped>
/* 卡片 */
#app_user_company_list>>>.el-card__header{
    padding: 8px 20px;
}

</style>