<template>
<!-- 客户展示 -->
<div>
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{company_name}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-pagination_height)+'px')}">
                <el-table :data="tableData"  border style="width: 100%" height="100%"  size="mini" >
                    <el-table-column  fixed  prop="cpid" label="客户名"  min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="send_type"  label="发送类型"  min-width="100" align="center"> </el-table-column>
                    <el-table-column  prop="states"  label="客户状态"  min-width="100" align="center"> </el-table-column>
                    <el-table-column  prop="yld_level"  label="客户类型"  min-width="100" align="center"> </el-table-column>
                    <el-table-column  prop="user_type"  label="用户付费类型"  min-width="100" align="center"> </el-table-column>
                    <el-table-column   label="操作"  min-width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini"  @click="detail(scope.row.cpid)">客户详情</el-button>
                        </template>
                    </el-table-column>
                   
                </el-table>
            </el-row>
            <el-row id="show_user_pagination_id" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination  background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10, 20, 30, 40]"
                     :page-size="10"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>

           
            

        </el-row>


    </el-dialog>

    <!-- 客户详情组件 -->
    <UserDetailSelect :prop_change_i="prop_user_detail_i"
        :prop_cpid="prop_user_detail_cpid"
    ></UserDetailSelect>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import UserDetailSelect from '../user/user_detail_select.vue';//组件 客户详情-弹出层

//import axios from "axios";
export default {
     components: {
        UserDetailSelect
    },
     props:{
        prop_change_i:0,
        company_id:0,//公司id
        company_name:"",//公司名
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                 
                setTimeout(() => {
                    //获取数据  -- 请求接口
                    this.getData();
                }, 1);
            }
            
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度


            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            
            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            pagination_height:0,//分页的高度


            //客户详情
            prop_user_detail_i:0,//客户详情  组件 弹出框是否可见
            prop_user_detail_cpid:"",//客户详情  组件  的cpid
            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 60);
            
            
        },
         //数据清除
        dataClear(){
            this.tableData = [];//表格数据
            this.tableData_total = 0;//表格数据总量
            // this.prop_user_detail_i = 0;//客户详情  组件 弹出框是否可见
            
            this.prop_user_detail_i = this.$options.data().prop_user_detail_i;//单个恢复初始化
        },
        //查询
        getData(){
            //请求接口
            API.CompanyServlet({
                param: "companyCpid",
                indexPage:this.indexPage,
                pageSize:this.pageSize,
                company_id:this.company_id,

            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;

                    this.$nextTick(function(){
                        //获取分页的高度
                        this.pagination_height = document.getElementById('show_user_pagination_id').clientHeight;
                    });

                    
                } else {
                }
                
            });
        },
        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.getData();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.getData();
        },

        //客户详情
        detail(cpid){
            console.log('客户详情 跳转到客户详情页面cpid:'+cpid);
            this.prop_user_detail_cpid = cpid;//客户详情  组件  的cpid
            this.prop_user_detail_i ++ ;//客户详情  组件 弹出框是否可见
            
        },


        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
</style>