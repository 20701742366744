<template>
<div>
    <!-- 客户公司 增加或修改 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>{{cur_title}}</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="120px" style="margin-bottom: 12px;">
                    <el-form-item label="公司名称">
                        <el-input v-model="form_data.company_name" placeholder="请输入公司名称" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="公司IP">
                        <el-input v-model="form_data.ip" placeholder="请输入公司IP,多个使用半角逗号隔开" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="签约公司">
                        <el-select v-model="form_data.signCompanyId"  size="mini" style="width:100%"> 
                            <el-option
                                v-for="item in signCompanyList"
                                :key="item.id"
                                :label="item.company_name"
                                :value="item.id"
                                >
                            </el-option>
                        </el-select>

                    </el-form-item>

                    <el-form-item label="通知类型">
                        <el-radio-group v-model="form_data.notice_type" size="mini" @change="form_data.notice_type == -1 ? form_data.notice_name='' : false">
                            <el-radio :label="-1">不通知</el-radio>
                            <el-radio :label="0">微信</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="通知群名" v-show="form_data.notice_type == 0">
                        <el-input v-model="form_data.notice_name" placeholder="请输入通道群名" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="接入端口号">
                        <el-input v-model="form_data.join_port" placeholder="请输入接入端口号" oninput="value=value.replace(/[^\d.]/g,'')"  size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="商务类型">
                        <el-radio-group v-model="form_data.business_type" size="mini">
                            <el-radio :label="0">渠道</el-radio>
                            <el-radio :label="1">直客</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="合同号">
                        <el-input v-model="form_data.contractnum" placeholder="请输入合同号" size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="备注">
                        <el-input v-model="form_data.note"  placeholder="请输入备注"  size="mini" type="textarea" rows="3"></el-input>
                    </el-form-item>

                    <el-form-item label="公司简称">
                        <el-input v-model="form_data.short_name"  placeholder="请输入公司简称"  size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="自动出账">
                        <el-radio-group v-model="form_data.is_auto" size="mini">
                            <el-radio :label="0">是</el-radio>
                            <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="邮箱地址">
                        <el-input v-model="form_data.post_email" placeholder="请输入邮箱地址,多个用半角逗号隔开"  size="mini" ></el-input>
                    </el-form-item>

                    <el-form-item label="抄送邮箱地址">
                        <el-input v-model="form_data.copy_email"  placeholder="请输入抄送邮箱地址,多个用半角逗号隔开"  size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="开票类型">
                        <el-radio-group v-model="form_data.ticket_type" size="mini">
                            <el-radio :label="-1">不开票</el-radio>
                            <el-radio :label="0">普票</el-radio>
                            <el-radio :label="1">专票</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="开票项目" v-show="form_data.ticket_type != -1">
                        <el-input v-model="form_data.ticket_product" placeholder="请输入开票项目"  size="mini"></el-input>
                    </el-form-item>

                    <el-form-item label="开票信息" v-show="form_data.ticket_type != -1">
                        <el-input v-model="form_data.ticket_msg"  placeholder="请输入开票信息"  size="mini" type="textarea" rows="3"></el-input>
                    </el-form-item>

                    <el-form-item label="邮寄信息">
                        <el-input v-model="form_data.post_msg"  placeholder="请输入邮寄信息"  size="mini" type="textarea" rows="3"></el-input>
                    </el-form-item>

                    <el-form-item label="注意事项">
                        <el-input v-model="form_data.post_note" placeholder="请输入注意事项"  size="mini" type="textarea" rows="3"></el-input>
                    </el-form-item>

                    <el-form-item label="调价通知邮箱">
                        <el-input v-model="form_data.send_email" placeholder="请输入调价通知邮箱,多个用半角逗号隔开"  size="mini"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>


            </el-row>

        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_type:'',//类型 add:增加  upd:修改
        prop_item:{},//公司对象
    },
    watch:{//监听props
        prop_change_i(val){
            console.log("客户公司增加或修改：val:"+val);
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                if(this.prop_type === "upd"){//修改
                    this.cur_title = "客户公司的修改";
                    setTimeout(() => {
                        //获取数据
                        this.getData();

                        //请求接口-获取签约公司列表
                        this.getSignCompanyList();
                    }, 1);
                }else{
                    this.cur_title = "客户公司的增加";
                    setTimeout(() => {
                        //请求接口-获取签约公司列表
                        this.getSignCompanyList();
                    }, 1);
                }
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"客户公司",//当前页面的标题头
            signCompanyList:[],//签约公司列表

            form_data:{
                id:"",//公司id
                company_name:'',//公司名称
                ip:'',//公司IP
                signCompanyId:'',//签约公司ID
                signCompanyName:'',//签约公司
                notice_type:0,//通知类型  -1:不通知   0:微信
                notice_name:'',//通知群名
                join_port:'',//接入端口号
                contractnum:'',//合同号
                business_type:0,//商务类型，0:渠道；1: 直客
                note:'',//备注
                short_name:'',//公司简称
                is_auto:0,//自动出账： 0:是 1:否
                post_email:'',//邮箱地址
                copy_email:'',//抄送邮箱地址
                ticket_type:0,//开票类型 -1:不开票 0:普票 1:专票
                ticket_msg:'',//开票信息
                ticket_product:'',//开票项目
                post_msg:'',//邮寄信息
                post_note:'',//注意事项
                send_email:'',//调价通知邮箱地址
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        //查询
        getData(){
            this.form_data.id = this.prop_item.id;//公司ID
            this.form_data.company_name = this.prop_item.company_name;//公司名称
            this.form_data.ip = this.prop_item.ip;//公司IP
            this.form_data.signCompanyId = this.prop_item.signCompanyId;//签约公司ID
            this.form_data.signCompanyName = this.prop_item.signCompanyName;//签约公司
            this.form_data.notice_type = this.prop_item.notice_type;//通知类型  -1:不通知   0:微信
            this.form_data.notice_name = this.prop_item.notice_name;//通知群名
            this.form_data.join_port = this.prop_item.join_port;//接入端口号
            this.form_data.contractnum = this.prop_item.contractnum;//合同号
            this.form_data.business_type = this.prop_item.business_type;//商务类型，0:渠道；1: 直客
            this.form_data.note = this.prop_item.note;//备注
            this.form_data.short_name = this.prop_item.short_name;//公司简称
            this.form_data.is_auto = this.prop_item.is_auto;//自动出账： 0:是 1:否
            this.form_data.post_email = this.prop_item.post_email;//邮箱地址
            this.form_data.copy_email = this.prop_item.copy_email;//抄送邮箱地址
            this.form_data.ticket_type = this.prop_item.ticket_type;//开票类型 -1:未知 0:普票 1:专票
            this.form_data.ticket_msg = this.prop_item.ticket_msg;//开票信息
            this.form_data.ticket_product = this.prop_item.ticket_product;//开票项目
            this.form_data.post_msg = this.prop_item.post_msg;//邮寄信息
            this.form_data.post_note = this.prop_item.post_note;//注意事项
            this.form_data.send_email = this.prop_item.send_email;//调价通知邮箱地址

            
        },
        //请求接口-获取签约公司列表
        getSignCompanyList(){
            API.CompanyServlet({
                param: "signCompany",
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.signCompanyList = res.list;//id + company_name
                    var obj = {"id":"","company_name":"请选择"}
                    this.signCompanyList.unshift(obj);//数组第一个位置插入元素
                }
            });
        },
        


        //签约公司 组件返回结果
        uerSignCompanyResult(company_id,company_name){
            form_data.signCompanyName = company_id;
        },

        //提交
        onSubmit(){
            console.log("提交");
            var submit_obj = {};
            if(this.prop_type === "upd"){//修改
                submit_obj.param = "upd";
                submit_obj.id = this.form_data.id;//公司ID
            }else{//增加
                submit_obj.param = "add";
            }
            submit_obj.company_name = this.form_data.company_name;//公司名称
            submit_obj.ip = this.form_data.ip;//公司IP
            submit_obj.signCompanyId = this.form_data.signCompanyId;//签约公司ID
            submit_obj.notice_type = this.form_data.notice_type;//通知类型  -1:不通知   0:微信
            submit_obj.notice_name = this.form_data.notice_name;//通知群名
            submit_obj.join_port = this.form_data.join_port;//接入端口号
            submit_obj.contractnum = this.form_data.contractnum;//合同号
            submit_obj.business_type = this.form_data.business_type;//商务类型，0:渠道；1: 直客
            submit_obj.note = this.form_data.note;//备注
            submit_obj.short_name = this.form_data.short_name;//公司简称
            submit_obj.is_auto = this.form_data.is_auto;//自动出账： 0:是 1:否
            submit_obj.post_email = this.form_data.post_email;//邮箱地址
            submit_obj.copy_email = this.form_data.copy_email;//抄送邮箱地址
            submit_obj.ticket_type = this.form_data.ticket_type;//开票类型 -1:不开票 0:普票 1:专票
            submit_obj.ticket_msg = this.form_data.ticket_msg;//开票信息
            submit_obj.ticket_product = this.form_data.ticket_product;//开票项目
            submit_obj.post_msg = this.form_data.post_msg;//邮寄信息
            submit_obj.post_note = this.form_data.post_note;//注意事项
            submit_obj.send_email = this.form_data.send_email;//调价通知邮箱地址

            API.CompanyServlet(submit_obj).then((res) => {
                
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>