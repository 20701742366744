<template>
<div id="channel_price_upd_log">
    <!-- 客户详情-弹出层 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>客户详情</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
               
               
                <el-row class="dialog_top cursor" @click.native="show_base = !show_base" style="text-align: left;padding-left:15px;">
                    <span>基本信息</span>
                    <i class="dialog_top_close_button" :class="show_base ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>
                <!-- 基本信息 -->
                <el-table v-if="show_base" :data="tableData_base" :show-header="false"  style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="label"  width="130"  align="left"> </el-table-column>
                    <el-table-column  prop="show_value"  min-width="200" align="right"> </el-table-column>
                </el-table>

                <el-row class="dialog_top cursor" @click.native="show_high = !show_high" style="text-align: left;padding-left:15px;">
                    <span>高级属性</span>
                    <i class="dialog_top_close_button" :class="show_high ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>
                <!-- 高级属性 -->
                <el-table v-if="show_high" :data="tableData_high" :show-header="false"  style="width: 100%"  size="mini" >
                    <el-table-column  fixed  prop="label"  width="130"  align="left"> </el-table-column>
                    <el-table-column  min-width="200" align="right">
                        <template slot-scope="scope">
                            <template v-if="scope.row.name == 'channel_select_type' && detail_obj.channel_select_type == 0">
                                <span>{{scope.row.show_value}}</span>
                                <el-button type="text" size="mini" @click="tableData_channel_group_isshow = !tableData_channel_group_isshow"  style="margin-left:10px;">{{tableData_channel_group_isshow ? '折叠' : '展示'}}</el-button>
                            </template>
                            <template v-else-if="scope.row.name == 'send_citys_array'">
                                <span v-html="scope.row.show_value"></span>
                            </template>
                            <template v-else>
                                {{scope.row.show_value}}
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 指定通道组列表 -->
                <el-table v-show="show_high && tableData_channel_group_isshow" :data="tableData_channel_group" :show-header="false"  style="width: 100%"  size="mini" >
                    <el-table-column  min-width="110"  align="center"> 
                        <template slot-scope="scope">
                           {{scope.row.channel_name}}(ID:{{scope.row.channel_id}})
                        </template>
                    </el-table-column>
                    <el-table-column  width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini"  @click="channel_detail(scope.row.channel_id)">通道详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>


                
                                    
            </el-row>
            
        </el-row>


    </el-dialog>

    <!-- 通道详情-弹出层组件 -->
    <ChannelDetailSelect :prop_change_i="prop_channel_detail_i" :prop_channel_id="prop_channel_detail_id"></ChannelDetailSelect>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';
import ChannelDetailSelect from '../channel/channel_detail_select.vue';//组件 通道详情-弹出层

//import axios from "axios";
export default {
     components: {
       ChannelDetailSelect
    },
    props:{
        prop_change_i:0,
        prop_cpid:""
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度


            show_base:true,//客户基本信息 是否展示  true:展示
            show_high:true,//客户高级属性 是否展示  true:展示
            tableData_base:[],//客户基本信息数据
            tableData_high:[],//客户高级属性数据
            tableData_channel_group:[],//客户指定通道组
            tableData_channel_group_isshow:false,//客户指定通道组是否展示  true:展示

            //通道详情
            prop_channel_detail_i:0,//弹出框是否可见
            prop_channel_detail_id:"",//通道id

           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
        },
         //数据清除
        dataClear(){
            this.show_base = this.$options.data().show_base;//单个恢复初始化
            this.show_high = this.$options.data().show_high;//单个恢复初始化
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化
            this.tableData_high= this.$options.data().tableData_high;//单个恢复初始化
            this.tableData_channel_group = this.$options.data().tableData_channel_group;//单个恢复初始化
            this.tableData_channel_group_isshow = this.$options.data().tableData_channel_group_isshow;//单个恢复初始化
          
            this.prop_channel_detail_i = this.$options.data().prop_channel_detail_i;//单个恢复初始化
        },
        //跳转到客户
        to_user(){
            API.router_to("/user_detail/"+this.prop_cpid);
        },

         //通道详情
        channel_detail(channel_id){
            console.log("通道详情"+channel_id);
            this.prop_channel_detail_id = channel_id;//通道id
            this.prop_channel_detail_i ++;//弹出框是否可见
        },

        //查询
        getData(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化
            this.tableData_high = this.$options.data().tableData_high;//单个恢复初始化
            this.tableData_channel_group = this.$options.data().tableData_channel_group;//单个恢复初始化

            var cpid = this.prop_cpid;//客户id
           //请求接口
            API.UserServlet({
                param: "detail",
                cpid:cpid,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    this.detail_obj = res.detail;
                    this.tableData_channel_group =  res.detail.user_channel_groups;////通道选取方式为指定通道组时，指定的通道列表
                    var show_value = "";


                    var table_obj = {label:"客户账号",show_value:res.detail.cpid,value:res.detail.cpid,name:"cpid"};
                    this.tableData_base.push(table_obj);

                    if( 1 == 1){
                        table_obj = {label:"公司名",show_value:res.detail.company_name,value:res.detail.company_name,name:"company_name"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"公司商务类型",show_value:res.detail.business_type == 0 ? '渠道' : '直客',value:res.detail.business_type,name:"business_type"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"公司鉴权IP",show_value:res.detail.ip == '' ? res.detail.company_ip : '无效',value:res.detail.company_ip,name:"company_ip"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"公司累计充值",show_value:res.detail.nummax,value:res.detail.nummax,name:"nummax"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"公司剩余金额",show_value:res.detail.blanceNum,value:res.detail.blanceNum,name:"blanceNum"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"登录密码",show_value:res.detail.login_pwd,value:res.detail.login_pwd,name:"login_pwd"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"接口密码",show_value:res.detail.cppwd,value:res.detail.cppwd,name:"cppwd"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"私钥",show_value:res.detail.secret,value:res.detail.secret,name:"secret"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"联系方式",show_value:res.detail.relationphone,value:res.detail.relationphone,name:"relationphone"};
                        this.tableData_base.push(table_obj);

                        if(res.detail.states == 0){
                            show_value = "可用";
                        }else if(res.detail.states == 1){
                            show_value = "注销";
                        }else if(res.detail.states == 2){
                            show_value = "已删除";
                        }else if(res.detail.states == 3){
                            show_value = "暂停";
                        }else{
                            show_value = "未知";
                        }
                        table_obj = {label:"客户状态",show_value:show_value,value:res.detail.states,name:"states"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户付费类型",show_value:res.detail.user_type == 0 ? '预付费' : '后付费',value:res.detail.user_type,name:"user_type"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"失败是否返还",show_value:res.detail.fail_rtid == '' ? '不返还' : '返还',value:res.detail.fail_rtid,name:"fail_rtid"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户鉴权IP",show_value:res.detail.ip,value:res.detail.ip,name:"ip"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"客户接入方式",show_value:res.detail.interface_type == 0 ? 'CMPP' : 'HTTP',value:res.detail.interface_type,name:"interface_type"};
                        this.tableData_base.push(table_obj);

                        if(res.detail.interface_type != 0){//接入方式，0:CMPP；1:HTTP

                            table_obj = {label:"http对接协议",show_value:res.detail.qxt_http_type_name,value:res.detail.qxt_http_type_name,name:"qxt_http_type_name"};
                            this.tableData_base.push(table_obj);

                            table_obj = {label:"回调地址",show_value:res.detail.url,value:res.detail.url,name:"url"};
                            this.tableData_base.push(table_obj);

                            table_obj = {label:"上行地址",show_value:res.detail.mourl,value:res.detail.mourl,name:"mourl"};
                            this.tableData_base.push(table_obj);
                        }

                        if(res.detail.user_type == 0){//预付费
                            table_obj = {label:"余额提醒",show_value:res.detail.blanceCallNum == -1 ? '不提醒' : '提醒',value:res.detail.blanceCallNum,name:"blanceCallNum"};
                            this.tableData_base.push(table_obj);
                            if(res.detail.blanceCallNum != -1){
                                table_obj = {label:"提醒金额(分)",show_value:res.detail.blanceCallNum+'分',value:res.detail.blanceCallNum,name:"blanceCallNum"};
                                this.tableData_base.push(table_obj);
                                table_obj = {label:"提醒目标号码",show_value:res.detail.phones,value:res.detail.phones,name:"phones"};
                                this.tableData_base.push(table_obj);
                            }
                        }

                        table_obj = {label:"关联微信",show_value:res.detail.wxArray.length,value:res.detail.wxArray.length,name:"wxArray"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"已发送条数",show_value:res.detail.havedNum,value:res.detail.havedNum,name:"havedNum"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"当日已发条数",show_value:res.detail.currentNum,value:res.detail.currentNum,name:"currentNum"};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"备注",show_value:res.detail.remark,value:res.detail.remark,name:"remark"};
                        this.tableData_base.push(table_obj);
                    }

                    

                    //------高级-----------------------------------------------------------------------------------------------
                    if(2 == 2){
                        table_obj = {label:"未知返还比例",show_value:res.detail.unknownReturn+"%",value:res.detail.unknownReturn,name:"unknownReturn"};
                        this.tableData_high.push(table_obj);

                        if(res.detail.yld_level == 1){
                            show_value = "验证码";
                        }else if(res.detail.yld_level == 2){
                            show_value = "行业";
                        }else if(res.detail.yld_level == 3){
                            show_value = "营销";
                        }else if(res.detail.yld_level == 4){
                            show_value = "四类";
                        }else{
                            show_value = "未知";
                        }
                        table_obj = {label:"客户类型",show_value:show_value,value:res.detail.yld_level,name:"yld_level"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.yld_level == 1 || res.detail.yld_level == 2){
                            table_obj = {label:"行业发营销监控",show_value:res.detail.hy_send_yx_onoff == 0 ? '打开' : '关闭',value:res.detail.hy_send_yx_onoff,name:"hy_send_yx_onoff"};
                            this.tableData_high.push(table_obj);
                            if(res.detail.hy_send_yx_onoff == 0 ){//行业发营销监控开关  0:开；1:关
                                table_obj = {label:"行业发营销最低利润",show_value:res.detail.hy_send_yx_profit_money+'分/条',value:res.detail.hy_send_yx_profit_money,name:"hy_send_yx_profit_money"};
                                this.tableData_high.push(table_obj);
                            }
                        }

                        if(res.detail.sms_type != 2){//多媒体短信
                            table_obj = {label:"回T退订",show_value:res.detail.isT == 0 ? '关闭' : '打开',value:res.detail.isT,name:"isT"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"日限",show_value:res.detail.isDayNum == -1 ? '不限' : res.detail.isDayNum+'条/天',value:res.detail.isDayNum,name:"isDayNum"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"号码限流",show_value:res.detail.isminutetime == 0 ? '不限制' : res.detail.isminutenum+'条/'+res.detail.isminutetime+'分钟',value:res.detail.isminutetime,name:"isminutetime"};
                        this.tableData_high.push(table_obj);

                        if(res.detail.sms_type != 2){//多媒体短信
                            table_obj = {label:"签名位置",show_value:res.detail.sign_position == 0 ? '强制前置' : res.detail.sign_position == 1 ? '强制后置' : '不管签名位置',value:res.detail.sign_position,name:"sign_position"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"自动加签名",show_value:res.detail.sign_add == '' ? '不加' : res.detail.sign_add,value:res.detail.sign_add,name:"sign_add"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"双签名修改开关",show_value:res.detail.isDoublesign == 0 ? '关闭' : '打开',value:res.detail.isDoublesign,name:"isDoublesign"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"头尾双签名拒发开关",show_value:res.detail.is_top_end_sign == 0 ? '关闭' : '打开',value:res.detail.is_top_end_sign,name:"is_top_end_sign"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"验证码过滤词",show_value:res.detail.yzmKeyword == 0 ? '不验证' : res.detail.yzmKeyword == 1 ? '包含转人工' : '包含拦截',value:res.detail.yzmKeyword,name:"yzmKeyword"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.yzmKeyword != 0 ){
                            table_obj = {label:"验证码过滤词库",show_value:res.detail.keywords_id_YZMName,value:res.detail.keywords_id_YZMName,name:"keywords_id_YZMName"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"账号过滤词",show_value:res.detail.isKeyword == 0 ? '不验证' : res.detail.isKeyword == 1 ? '包含转人工' : '包含拦截',value:res.detail.remark,name:"remark"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.isKeyword != 0 ){
                            table_obj = {label:"账号过滤词库",show_value:res.detail.keywordGroupName,value:res.detail.keywordGroupName,name:"keywordGroupName"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"自有黑名单",show_value:res.detail.selfBlack == 0 ? '关闭' : '打开',value:res.detail.selfBlack,name:"selfBlack"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"运营商黑名单",show_value:res.detail.operBlack == 0 ? '关闭' : '打开',value:res.detail.operBlack,name:"operBlack"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"回T黑名单",show_value:res.detail.backBlack == 0 ? '关闭' : '打开',value:res.detail.backBlack,name:"backBlack"};
                        this.tableData_high.push(table_obj);
                        
                        table_obj = {label:"三方黑名单",show_value:res.detail.black_three_id_array_name == '' ? '无': res.detail.black_three_id_array_name,value:res.detail.black_three_id_array,name:"black_three_id_array"};
                        this.tableData_high.push(table_obj);

                        if(res.detail.interface_type == 1 && res.detail.qxt_http_type == 'system'){
                            
                            table_obj = {label:"http长短信状态推送",show_value:res.detail.isReportPush == 0 ? '单条' : '多条',value:res.detail.isReportPush,name:"isReportPush"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"http返回状态时间",show_value:res.detail.push_state_time == 0 ? '不推送' : '推送',value:res.detail.push_state_time,name:"push_state_time"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"http推送计费条数",show_value:res.detail.push_snum == 0 ? '不推送' : '推送',value:res.detail.push_snum,name:"push_snum"};
                            this.tableData_high.push(table_obj);

                        }

                        if(res.detail.interface_type == 0){////接入方式，0:CMPP；1:HTTP
                            table_obj = {label:"CMPP最大发送速度",show_value:res.detail.speedMax+'条/秒',value:res.detail.speedMax,name:"speedMax"};
                            this.tableData_high.push(table_obj);
                        }

                        if(res.detail.sms_type == 0){
                            show_value = "短信";
                        }else if(res.detail.sms_type == 1){
                            show_value = "语音";
                        }else if(res.detail.sms_type == 2){
                            show_value = "多媒体短信";
                        }else{
                            show_value = "未知";
                        }
                        table_obj = {label:"客户短信类型",show_value:show_value,value:res.detail.sms_type,name:"sms_type"};
                        this.tableData_high.push(table_obj);

                        if(res.detail.sms_type == 0){//客户短信类型:0:短信
                            table_obj = {label:"固话短信开关",show_value:res.detail.fixed_line_onoff == 0 ? '关闭' : '打开',value:res.detail.fixed_line_onoff,name:"fixed_line_onoff"};
                            this.tableData_high.push(table_obj);

                            if(res.detail.fixed_line_onoff == 1){//固话短信开关 0：关闭；1：打开 ,只有当发送类别选择短信时，可显示
                                table_obj = {label:"固话短信可发省份",show_value:res.detail.fixed_line_province_str,value:res.detail.fixed_line_province,name:"fixed_line_province"};
                                this.tableData_high.push(table_obj);
                            }
                        }

                        table_obj = {label:"自定义扩展码",show_value:res.detail.own_ext == '' ? '不允许' : res.detail.own_ext,value:res.detail.own_ext,name:"own_ext"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"签名库",show_value:res.detail.sign_library_id == 0 ? '不验证签名库' : res.detail.sign_library_name,value:res.detail.sign_library_name,name:"sign_library_name"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.sign_library_id != 0){
                            table_obj = {label:"签名扩展类型",show_value:res.detail.sign_ext_onoff == 0 ? '使用签名库扩展' : res.detail.sign_ext_onoff == 1 ? '使用签名库扩展+客户扩展' : '使用客户扩展',value:res.detail.sign_ext_onoff,name:"sign_ext_onoff"};
                            this.tableData_high.push(table_obj);
                            table_obj = {label:"签名库自加签名",show_value:res.detail.sign_after == 0 ? '允许' : '不允许',value:res.detail.sign_after,name:"sign_after"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"移动",show_value:res.detail.yd_refuse == 0 ? '可发' : '拒发',value:res.detail.yd_refuse,name:"yd_refuse"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"联通",show_value:res.detail.lt_refuse == 0 ? '可发' : '拒发',value:res.detail.lt_refuse,name:"lt_refuse"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"电信",show_value:res.detail.dx_refuse == 0 ? '可发' : '拒发',value:res.detail.dx_refuse,name:"dx_refuse"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"可发省份",show_value:res.detail.send_pro_str,value:res.detail.send_pro,name:"send_pro"};
                        this.tableData_high.push(table_obj);

                        if(res.detail.send_citys_array.length > 0){
                            table_obj = {label:"可发省份下的地市",show_value:res.detail.send_citys_array_str,value:res.detail.send_citys_array,name:"send_citys_array"};
                            this.tableData_high.push(table_obj);
                        }

                        if(res.detail.sms_type != 2){//多媒体短信
                            table_obj = {label:"验证码识别开关",show_value:res.detail.checknum_onoff == 0 ? '关闭' : '打开',value:res.detail.checknum_onoff,name:"checknum_onoff"};
                            this.tableData_high.push(table_obj);

                            table_obj = {label:"全局模板开关",show_value:res.detail.all_template_onoff == 0 ? '关闭' : '打开',value:res.detail.all_template_onoff,name:"all_template_onoff"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"强制不审核开关",show_value:res.detail.ischeck == 0 ? '关闭' : '打开',value:res.detail.ischeck,name:"ischeck"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"客户接入码",show_value:res.detail.access_code == '' ? '无' : res.detail.access_code,value:res.detail.access_code,name:"access_code"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"客户平台权限",show_value:res.detail.user_power == '' ? '无' : '自助下行导出',value:res.detail.user_power,name:"user_power"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"预警策略",show_value:res.detail.warning_id == 0 ? '不预警' : res.detail.warning_name,value:res.detail.warning_id,name:"warning_id"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否支持携号转网",show_value:res.detail.change_oper_onoff == 0 ? '不支持' : '支持',value:res.detail.change_oper_onoff,name:"change_oper_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否允许带链接",show_value:res.detail.link_is == 0 ? '允许' : '不允许',value:res.detail.link_is,name:"link_is"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.link_is == 0){
                            table_obj = {label:"带链接的信任级别",show_value:res.detail.link_trust_level == 0 ? '信任' : '不信任',value:res.detail.link_trust_level,name:"link_trust_level"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"客户重发策略",show_value:res.detail.user_repeat_strategy_id == 0 ? '不重发' : res.detail.user_repeat_strategy_name,value:res.detail.user_repeat_strategy_id,name:"user_repeat_strategy_id"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"要求扩展码位数",show_value:res.detail.ext_num == 0 ? '不要求' : res.detail.ext_num+'位',value:res.detail.ext_num,name:"ext_num"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"上行是否要求",show_value:res.detail.mo_onoff == 0 ? '要求' : '不要求',value:res.detail.mo_onoff,name:"mo_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"特殊分类标签",show_value:res.detail.special_label == '' ? '无特殊标签' : res.detail.special_label,value:res.detail.special_label,name:"special_label"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否允许带联系方式",show_value:res.detail.contact_onoff == 0 ? '允许' : '不允许',value:res.detail.contact_onoff,name:"contact_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否允许发送纯英文",show_value:res.detail.pure_english_onoff == 0 ? '允许' : '不允许',value:res.detail.pure_english_onoff,name:"pure_english_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"英文签名是否可发",show_value:res.detail.english_sign_onoff == 0 ? '不可发' : '可发',value:res.detail.english_sign_onoff,name:"english_sign_onoff"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"单条最低利润",show_value:res.detail.profit_min+"分",value:res.detail.profit_min,name:"profit_min"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"单日赔钱最大值",show_value:res.detail.day_pay_max+"元",value:res.detail.day_pay_max,name:"day_pay_max"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"当日赔钱数",show_value:res.detail.pay_money+"元",value:res.detail.pay_money,name:"pay_money"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"审核方式",show_value:res.detail.check_type == 0 ? '免审' : res.detail.check_type == 1 ? '必审' : res.detail.check_type == 2 ? '系统抽样审' : res.detail.check_type == 3 ? '无模板拒发': '预报备模板',value:res.detail.check_type,name:"check_type"};
                        this.tableData_high.push(table_obj);

                        var send_hhmm_start = res.detail.send_hhmm_start+"";
                        var send_hhmm_end = res.detail.send_hhmm_end+"";
                        var send_hhmm_start_str = "";
                        var send_hhmm_end_str = "";
                        if(send_hhmm_start.length == 1){
                            send_hhmm_start_str = "0点";
                        }if(send_hhmm_start.length == 2){
                            send_hhmm_start_str = "0点半";
                        }else if(send_hhmm_start.length == 3){
                            var send_hhmm_end_2 = send_hhmm_start.substring(1);
                            if(send_hhmm_end_2 == '00'){
                                send_hhmm_start_str = send_hhmm_start.substring(0,1)+"点";
                            }else{
                                send_hhmm_start_str = send_hhmm_start.substring(0,1)+"点半";
                            }
                        }else if(send_hhmm_start.length == 4){
                            var send_hhmm_end_2 = send_hhmm_start.substring(2);
                            if(send_hhmm_end_2 == '00'){
                                send_hhmm_start_str = send_hhmm_start.substring(0,2)+"点";
                            }else{
                                send_hhmm_start_str = send_hhmm_start.substring(0,2)+"点半";
                            }
                        }
                        if(send_hhmm_end.length == 1){
                            send_hhmm_end_str = "0点";
                        }if(send_hhmm_end.length == 2){
                            send_hhmm_end_str = "0点半";
                        }else if(send_hhmm_end.length == 3){
                            var send_hhmm_end_2 = send_hhmm_end.substring(1);
                            if(send_hhmm_end_2 == '00'){
                                send_hhmm_end_str = send_hhmm_end.substring(0,1)+"点";
                            }else{
                                send_hhmm_end_str = send_hhmm_end.substring(0,1)+"点半";
                            }
                        }else if(send_hhmm_end.length == 4){
                            var send_hhmm_end_2 = send_hhmm_end.substring(2);
                            if(send_hhmm_end_2 == '00'){
                                send_hhmm_end_str = send_hhmm_end.substring(0,2)+"点";
                            }else{
                                send_hhmm_end_str = send_hhmm_end.substring(0,2)+"点半";
                            }
                        }
                        show_value = send_hhmm_start_str+"到"+send_hhmm_end_str;
                        table_obj = {label:"可发送时段",show_value:show_value,value:res.detail.send_hhmm_start,name:"send_hhmm_start"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"是否要求指定码号",show_value:res.detail.specify_codenum_onff == 0 ? '要求' : '不要求',value:res.detail.specify_codenum_onff,name:"specify_codenum_onff"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.specify_codenum_onff == 0 ){
                            table_obj = {label:"指定码号",show_value:res.detail.specify_codenum,value:res.detail.specify_codenum,name:"specify_codenum"};
                            this.tableData_high.push(table_obj);
                        }

                        table_obj = {label:"灵狐审核类型",show_value:res.detail.fox_type == -1 ? '不验证' : res.detail.fox_type == 0 ? '极速' : 'AI',value:res.detail.fox_type,name:"fox_type"};
                        this.tableData_high.push(table_obj);
                        if(res.detail.fox_type != -1 ){
                            table_obj = {label:"灵狐抽样比率",show_value:res.detail.sampling_rate+"‱",value:res.detail.sampling_rate,name:"sampling_rate"};
                            this.tableData_high.push(table_obj);
                        }
                        
                        show_value = "";
                        var channel_select_style = res.detail.channel_select_style;//可选通道类别，0:直连；1:准直连；2:三方；3:厂商，复选框(多个使用半角逗号隔开)
                        var channel_select_style_array = channel_select_style.split(",");
                        for(var i =0;i<channel_select_style_array.length;i++){
                            var one = channel_select_style_array[i];
                            if(show_value != ''){
                                show_value += ",";
                            }
                            if(one == '0'){
                                show_value += "直连";
                            }else if(one == '1'){
                                show_value += "准直连";
                            }else if(one == '2'){
                                show_value += "三方";
                            }else if(one == '3'){
                                show_value += "厂商";
                            }
                        }

                        table_obj = {label:"可选通道类型",show_value:show_value,value:res.detail.channel_select_style,name:"channel_select_style"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"发送类型",show_value:res.detail.send_type == '' ? '无' : res.detail.send_type,value:res.detail.send_type,name:"send_type"};
                        this.tableData_high.push(table_obj);

                        table_obj = {label:"通道选取方式",show_value:res.detail.channel_select_type == 0 ? '指定通道组' : '自动选通道',value:res.detail.send_type,name:"channel_select_type"};
                        this.tableData_high.push(table_obj);


                    }
           
                }
                
            });
        },

        
        

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

</style>